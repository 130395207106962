"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.currenciesKeys = exports.rolesKeys = exports.requestProfileKeys = exports.requestKeys = exports.featureKeys = exports.userKeys = exports.teamKeys = exports.codingKeys = void 0;
exports.codingKeys = {
    getCodingLists: 'getCodingLists',
    getProviderMapping: 'getProviderMapping',
    getProviderFields: 'getProviderFields',
    getCodingListsEntries: 'getCodingListsEntries',
    setCodingListsEntries: 'setCodingListsEntries',
    getCodingListEntries: 'getCodingListEntries',
    getTeamCodingListEntries: 'getTeamCodingListEntries',
    getInactiveCodingListEntries: 'getInactiveCodingListEntries',
};
exports.teamKeys = {
    getTeams: ({ state, limit, offset }) => ['teams', state, limit, offset],
    getAllTeams: () => ['all teams'],
    getTeamProperty: 'getTeamProperty',
    getReport: 'getReport',
    getUserSettings: 'getUserSettings',
    getTeam: 'getTeam',
    getDecoratedUser: 'getDecoratedUser',
    getTeamUsers: 'getTeamUsers',
    verifyTeamAccess: 'verifyTeamAccess',
    getSelectedTeamProperty: 'getSelectedTeamProperty',
    getOooSettings: (teamId, userId) => ['oooSettings', teamId, userId],
};
exports.userKeys = {
    getUser: ['user'],
    getUserSettings: 'getUserSettings',
    getUserByEmail: (email) => ['users', 'email', email],
    getUsersByRoleId: (roleId) => ['users', 'roleId', roleId],
    getUserById: (id) => ['user', 'id', id],
    getVerifyUserAccess: (params) => ({
        ...params,
    }),
    verifyTeamAccess: 'verifyTeamAccess',
};
exports.featureKeys = {
    getFeatureList: 'getFeatureList',
};
exports.requestKeys = {
    getRequestId: ['request', 'id'],
    getRequestList: ['request', 'list'],
    getRequest: (requestId) => ['request', requestId],
    getRequests: (archived) => ['requests', archived],
    addTag: (requestId, tag) => ['request', requestId, 'tag', tag, 'add'],
    removeTag: (requestId, tag) => ['request', requestId, 'tag', tag, 'remove'],
    approve: (requestId) => ['request', requestId, 'approve'],
    reject: (requestId) => ['request', requestId, 'reject'],
    cancelRequest: (requestId) => ['request', requestId, 'cancel'],
    submitRequest: (requestId) => ['request', requestId, 'submit'],
};
exports.requestProfileKeys = {
    getRequestProfilesForCompany: 'getRequestProfilesForCompany',
    getProfiles: 'getProfiles',
};
exports.rolesKeys = {
    setRoles: 'setRoles',
    setRole: 'setRole',
    removeRoleFromUser: 'removeRoleFromUser',
};
exports.currenciesKeys = {
    getCurrencies: (teamId) => ['currency', 'teamId', teamId],
};
