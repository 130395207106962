"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiIconOverrides = void 0;
const tsGoThemeVars_1 = require("../tsGoThemeVars");
function getLabelCloseIconStyle(theme) {
    return {
        // fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        color: theme.palette.tsGoColor.gray,
        cursor: 'pointer',
        alignSelf: 'flex-start',
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                color: '#333333',
            }
            : {}),
        '&:hover': {
            color: theme.palette.tsGoColor.black,
        },
    };
}
function getMobileCloseIconStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.big),
        stroke: theme.palette.tsGoColor.gray,
        fontWeight: theme.typography.tsFontWeight.semiBold,
        strokeWidth: 3,
    };
}
function getCloseIconStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.big),
        fontWeight: 'bold',
        stroke: theme.palette.tsGoColor.white,
        strokeWidth: '3px',
        cursor: 'pointer',
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                strokeWidth: '1px',
                color: 'black',
                stroke: 'black',
            }
            : {}),
    };
}
function getArrowRightIconActiveStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
    };
}
function getSidebarHeaderIcon(theme, ownerState) {
    const defaultStyles = {
        position: 'absolute',
        backgroundSize: '15px 15px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        color: theme.palette.tsGoColor.gray,
        borderRadius: tsGoThemeVars_1.tsGoUnit.tsUnitOneAndHalf,
        height: tsGoThemeVars_1.tsGoUnit.tsUnitOneAndHalf,
        width: tsGoThemeVars_1.tsGoUnit.tsUnitOneAndHalf,
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                path: {
                    fill: 'white',
                },
            }
            : {}),
    };
    if (ownerState.className === 'animateLeft') {
        return {
            ...defaultStyles,
            transform: 'scaleX(-1)',
            opacity: 1,
            '&:hover': {
                animation: 'bounce 0.5s linear',
                backgroundColor: 'rgba(25, 34, 38, 1)',
            },
        };
    }
    else if (ownerState.className === 'animateRight') {
        return {
            ...defaultStyles,
            transform: 'rotate(0deg)',
            left: theme.spacing(-1),
        };
    }
    else {
        return {
            ...defaultStyles,
        };
    }
}
function getArrowRightIconDefaultStyle(theme) {
    return {
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
        color: theme.palette.tsGoColor.grayLight,
    };
}
function getCheckIconStyle() {
    return {
        position: 'absolute',
        right: '5px',
        top: '7px',
    };
}
function getTeamSwitcherCloseIconStyle(theme) {
    return {
        alignItems: 'center',
        borderRadius: tsGoThemeVars_1.tsGoUnit.tsUnitOneAndHalf,
        display: 'flex',
        flex: 1,
        height: tsGoThemeVars_1.tsGoUnit.tsUnitOneAndHalf,
        justifyContent: 'center',
        position: 'absolute',
        right: theme.tsGoUnit.tsUnitHalf,
        top: '15px',
        transition: 'min-width 0.3s ease-out, max-width 0.3s ease-out',
        width: tsGoThemeVars_1.tsGoUnit.tsUnitOneAndHalf,
        zIndex: 1,
        color: tsGoThemeVars_1.goPalette.tsGoColor.gray,
        cursor: 'pointer',
    };
}
function getTeamSwitcherCheckIconStyle() {
    return {
        position: 'absolute',
        right: tsGoThemeVars_1.tsGoUnit.tsUnitHalf,
        top: '15px',
        stroke: tsGoThemeVars_1.goPalette.tsGoColor.white,
    };
}
function getTeamSwitcherArrowIconStyle(theme) {
    return {
        position: 'absolute',
        right: tsGoThemeVars_1.tsGoUnit.tsUnit,
        top: '18px',
        color: theme.palette.tsGoColor.gray,
        fontSize: tsGoThemeVars_1.tsGoUnit.tsUnit,
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                path: {
                    fill: 'white',
                },
            }
            : {}),
    };
}
function getMyRequestStatusIcon(ownerState, theme) {
    const defaultStyles = {
        position: 'absolute',
        left: 0,
        top: tsGoThemeVars_1.tsGoUnit.tsUnit,
        strokeWidth: 1,
        marginLeft: '20px',
    };
    switch (ownerState.className) {
        case 'approved': {
            return {
                ...defaultStyles,
                '&.approved': {
                    stroke: `${globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                        ? theme.palette.hsbcColor.green
                        : theme.palette.tsGoColor.green} !important`,
                    color: `${globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? theme.palette.hsbcColor.green : theme.palette.tsGoColor.green} !important`,
                },
            };
        }
        case 'pending': {
            return {
                ...defaultStyles,
                '&.pending': {
                    color: `${tsGoThemeVars_1.goPalette.tsGoColor.yellow} !important`,
                },
            };
        }
        case 'error':
        case 'declined': {
            return {
                ...defaultStyles,
                '&.declined, &.error': {
                    stroke: `${tsGoThemeVars_1.goPalette.tsGoColor.red} !important`,
                    color: `${tsGoThemeVars_1.goPalette.tsGoColor.red} !important`,
                },
            };
        }
        default: {
            return {
                ...defaultStyles,
            };
        }
    }
}
exports.muiIconOverrides = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            ...(ownerState['ts-variant'] === 'labelCloseIcon' && getLabelCloseIconStyle(theme)),
            ...(ownerState['ts-variant'] === 'closeIcon' && getCloseIconStyle(theme)),
            ...(ownerState.className === 'arrowRightIcon' && getArrowRightIconDefaultStyle(theme)),
            ...(ownerState.className === 'arrowRightIcon active' && getArrowRightIconActiveStyle(theme)),
            ...(ownerState['ts-variant'] === 'sidebarHeaderIcon' && getSidebarHeaderIcon(theme, ownerState)),
            ...(ownerState['ts-variant'] === 'mobileCloseIcon' && getMobileCloseIconStyle(theme)),
            ...(ownerState['ts-variant'] === 'checkIcon' && getCheckIconStyle()),
            ...(ownerState['ts-variant'] === 'teamSwitcherCloseIcon' && getTeamSwitcherCloseIconStyle(theme)),
            ...(ownerState['ts-variant'] === 'teamSwitcherCheckIcon' && getTeamSwitcherCheckIconStyle()),
            ...(ownerState['ts-variant'] === 'teamSwitcherArrowIcon' && getTeamSwitcherArrowIconStyle(theme)),
            ...(ownerState['ts-variant'] === 'requestStatusIcon' && getMyRequestStatusIcon(ownerState, theme)),
        }),
    },
};
